import React, { useState, useEffect } from "react";
import i18n from "i18next";
import coverPicFR from "../img/coverImage-FR.png";
import coverPicEN from "../img/coverImage-EN.png";
import levelFR from "../img/level-FR.png";
import levelEN from "../img/level-EN.png";
import barbara from "../img/barbara.png";
import barbaraProfile from "../img/barbaraProfile.png";
// import marie from "../img/marie.png";
// import marieProfile from "../img/marieProfile.png";
import dennis from "../img/dennis.png";
import dennisProfile from "../img/dennisProfile.png";
import lionel from "../img/lionel.png";
import lionelProfile from "../img/lionelProfile.png";
import jeanne from "../img/jeanne.png";
import jeanneProfile from "../img/jeanneProfile.png";
// import mira from "../img/mira.png";
// import miraProfile from "../img/miraProfile.png";
import appStoreIcon from "../img/appStoreIcon.png";
import playStoreIcon from "../img/playStoreIcon.png";
import NavigationMenu from "../components/NavigationMenu";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import FontAwesome styles
import EmailSubscriptionForm from "../components/EmailSubscriptionForm"; // Import the new component
import { FontAwesome } from 'react-web-vector-icons';
 



const Home = () => {
  const { t } = useTranslation();
  var language = i18n.language.substring(0, 2);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  var coverPic;
  if (language === "fr") {
    coverPic = coverPicFR;
  } else if (language === "en") {
    coverPic = coverPicEN;
  }

  var explore;
  if (language === "fr") {
    explore = levelFR;
  } else if (language === "en") {
    explore = levelEN;
  }

  const cards1 = {
    card1: {
      emoji: "child",
      title: t("cards.card1Title"),
      description: t("cards.card1Content"),
    },
    card2: {
      emoji: "cloud",
      title: t("cards.card2Title"),
      description: t("cards.card2Content"),
    },
    card3: {
      emoji: "comments",
      title: t("cards.card3Title"),
      description: t("cards.card3Content"),
    },
  };
  const cards2 = {
    card4: {
      emoji: "spinner",
      title: t("cards.card4Title"),
      description: t("cards.card4Content"),
    },
    card5: {
      emoji: "gamepad",
      title: t("cards.card5Title"),
      description: t("cards.card5Content"),
    },
    card6: {
      emoji: "barcode",
      title: t("cards.card6Title"),
      description: t("cards.card6Content"),
    },
  };

  const faqs = {
    cat1: {
      uuid: "a",
      title: t("faq.cat1"),
      emoji: "book",
      text: t("faq.cat1Text"),
    },
    cat2: {
      uuid: "b",
      title: t("faq.cat2"),
      emoji: "list",
      text: t("faq.cat2Text"),
    },
    cat3: {
      uuid: "c",
      title: t("faq.cat3"),
      emoji: "id-badge",
      text: t("faq.cat3Text"),
    },
    cat4: {
      uuid: "d",
      title: t("faq.cat4"),
      emoji: "globe",
      text: t("faq.cat4Text"),
    },
  };

  const testimonials = {
    barbara: {
      profilePic: barbaraProfile,
      backgroundImage: barbara,
      title: t("testimonials.barbaraTitle"),
      subtitle: t("testimonials.barbaraSubtitle"),
      link: "https://www.instagram.com/barbaraopsomer",
      description: t("testimonials.barbaraDescription"),
    },
    lionel: {
      profilePic: lionelProfile,
      backgroundImage: lionel,
      title: t("testimonials.lionelTitle"),
      subtitle: t("testimonials.lionelSubtitle"),
      link: "https://www.instagram.com/lionel.ingende/",
      description: t("testimonials.lionelDescription"),
    },
    jeanne: {
      profilePic: jeanneProfile,
      backgroundImage: jeanne,
      title: t("testimonials.jeanneTitle"),
      subtitle: t("testimonials.jeanneSubtitle"),
      link: "https://www.instagram.com/jeanne_lhx/",
      description: t("testimonials.jeanneDescription"),
    },
    dennis: {
      profilePic: dennisProfile,
      backgroundImage: dennis,
      title: t("testimonials.dennisTitle"),
      subtitle: t("testimonials.dennisSubtitle"),
      link: "https://www.linkedin.com/in/dennis-nazare-088bb0ba/",
      description: t("testimonials.dennisDescription"),
    },
    // mira: {
    //   profilePic: miraProfile,
    //   backgroundImage: mira,
    //   title: t("testimonials.miraTitle"),
    //   subtitle: t("testimonials.miraSubtitle"),
    //   link: "https://www.instagram.com/mirasaadallah/",
    //   description: t("testimonials.miraDescription"),
    // },
    // marie: {
    //   profilePic: marieProfile,
    //   backgroundImage: marie,
    //   title: t("testimonials.marieTitle"),
    //   subtitle: "",
    //   description: t("testimonials.marieDescription"),
    // },
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // Adjust this if needed to avoid making the container too large
    },
    coverImage: {
      maxHeight: '75vh', // 75% of the viewport height
      maxWidth: '100%',  // Ensure the image does not exceed the container width
      width: 'auto',
    },
    storeIconsContainer: {
      display: "flex",
      justifyContent: screenWidth < 768 ? "center" : "flex-start", // Center on mobile
      alignItems: "center",
      marginTop: "20px",
    },
    storeIcon: {
      height: "50px",
      marginRight: "10px",
    },
    emailCoverContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    emailLevelContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    arrowEmoji: {
      marginRight: '1rem',
      fontSize: '1.5rem',
    },
    emailContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: screenWidth * 0.35,
    },
    emailInput: {
      flex: 1,
      marginRight: '1rem',
    },
    ctaButton: {
      flexShrink: 0,
    },
    spinner: {
      marginLeft: '8px',
      width: '16px',
      height: '16px',
      border: '2px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '50%',
      borderTopColor: '#fff',
      animation: 'spin 1s ease-in-out infinite',
    },
    checkmark: {
      marginLeft: '8px',
      color: 'white',
    },
  };

  return (
    <div>
      <NavigationMenu />
      <div className="container">
        <div className="columns mt-6 py-6 is-vcentered">
          <div className="column mt-6">
            <h1 className=" px-5 py-5 k2d">
              <span className="highlight">{t("cover.tagBold")}</span>{" "}
              {t("cover.tagline")}
            </h1>
            <p className="px-5 py-2 k2d" style={{ lineHeight: 1.5 }}>
              {t("cover.description")}
            </p>
            <div className="level px-5 my-6">
              <EmailSubscriptionForm screenWidth={screenWidth} position={'cover'}/> {/* Use the new component */}
            </div>
            <div style={styles.storeIconsContainer} className="level mx-5 my-6">
              <a href="https://apps.apple.com/app/id6503691556" target="_blank" rel="noopener noreferrer">
                <img src={appStoreIcon} alt="Download on the App Store" style={styles.storeIcon} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.anonymous.realentlessmobileapp" target="_blank" rel="noopener noreferrer">
                <img src={playStoreIcon} alt="Get it on Google Play" style={styles.storeIcon} />
              </a>
            </div>
          </div>
          <div className="column" style={styles.container}>
          <img style={styles.coverImage} src={coverPic} alt="Home cover"></img>
          </div>
        </div>
        {/* <div className="level has-centered-text is-mobile pt-6 px-6 mt-6">
          <div className="level-item level-right">
            <h2 className="k2d is-size-1">{t("cover.transform")}</h2>
          </div>
          <div className="level-item level-left mx-2">
            <h2 className="k2d is-size-1">{t("cover.your_life")}</h2>
            {/* <TypoAnimation style={{ style: "k2d highlight animation" }} /> */}
          {/* </div>
        </div> */}
        <div className="level px-5 mt-6 py-6">
          <div className="level-item">
            <h2 className="k2d is-size-1">{t("cover.transform")}{t("cover.your_life")}</h2>
          </div>
        </div>
        <div className="level mx-5 mt-6">
          <div className="level-item">
            <h2 className="py-2 k2d">{t("cards.subTitle")}</h2>
          </div>
        </div>
        <div className="columns mt-6 mx-3">
          {Object.keys(cards1).map((card) => (
            <div className="column" key={card}>
              <div className="card card-item">
                <div className="card-content">
                  <div className="content has-text-centered is-size-5 k2d mt-5 primary">

                    <FontAwesome
                      name={cards1[card].emoji}
                      size={30}
                      style={{ color: 'black'}}
                    />
                    {/* <Icon
                      name='map'
                      font='Entypo'
                      size={30}
                      // style={{}}
                    /> */}
                    
                  </div>
                  <div className="content has-text-centered is-size-5 k2d mt-5 tertiary">
                    {cards1[card].title}
                  </div>
                  <p className="has-text-centered k2d">
                    {cards1[card].description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="columns  my-6 mx-3">
          {Object.keys(cards2).map((card) => (
            <div className="column" key={card}>
              <div className="card card-item">
                <div className="card-content">
                <div className="content has-text-centered is-size-5 k2d mt-5 primary">
                  <FontAwesome
                    name={cards2[card].emoji}
                    size={30}
                    style={{ color: 'black'}}
                  />
                </div>
                  <div className="content has-text-centered is-size-5 k2d mt-5 tertiary">
                    {cards2[card].title}
                  </div>
                  <p className="has-text-centered k2d">
                    {cards2[card].description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>       
        <div className="level">
              <EmailSubscriptionForm screenWidth={screenWidth} position={'body'}/> {/* Use the new component */}
            </div>
        <div className="level px-5 mt-6 py-6">
          <div className="level-item has-text-centered level-is-shrinkable">
            <h2 className="k2d is-size-1">
              {t("pros.titleMain")}{" "}
              <span className="highlight">{t("pros.titleBold")}</span>
            </h2>
          </div>
        </div>
        <div className="columns pt-3 my-6">
          <div className="column faq-container">
            <h2 className=" px-5 py-3 k2d is-size-2">{t("faq.how")}</h2>
            <Accordion preExpanded={["a"]}>
              {Object.keys(faqs).map((faq) => (
                <AccordionItem uuid={faqs[faq].uuid} key={faq}>
                  <AccordionItemHeading>
                    <AccordionItemButton className="mb-2">
                      <a href="#0">
                        <div className="box maxBox">
                          <nav className="level">
                            <div className="level-left">
                              <div className="level-item">
                                <h3 className="k2d is-size-6">
                                  {faqs[faq].title}
                                </h3>
                              </div>
                            </div>
                            <div className="level-right">
                              <div className="level-item">
                                <div className="content has-text-centered is-size-5 k2d mt-5 primary">
                                  <FontAwesome
                                    name={faqs[faq].emoji}
                                    size={20}
                                    style={{ color: 'black'}}
                                  />
                                </div>
                              </div>
                            </div>
                          </nav>
                        </div>
                      </a>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="px-6 pt-4 pb-6">
                    <p className="desc k2d" style={{ lineHeight: 1.5 }}>
                      {faqs[faq].text}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div className="column faq-container pt-6" style={styles.container}>
          <img style={styles.coverImage} src={explore} alt="faq cover"></img>
          </div>
        </div>
        <div className="level">
              <EmailSubscriptionForm screenWidth={screenWidth} position={'body'}/> {/* Use the new component */}
            </div>
        <div className="level py-6 mt-6 px-5">
          <div className="level-item has-text-centered level-is-shrinkable">
            <h2 className="k2d is-size-1">
              {t("testimonials.start")}{" "}
              <span className="highlight">{t("testimonials.bold")}</span>
            </h2>
          </div>
        </div>
        <div className="box has-background-white has-text-grey-dark">
          <Carousel
            additionalTransfrom={0}
            arrows
            shouldResetAutoplay={false}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {Object.keys(testimonials).map((testimonial) => (
              <div className="card mx-3" key={testimonial}>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img
                      src={testimonials[testimonial].backgroundImage}
                      alt="Placeholder "
                    ></img>
                  </figure>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-48x48 ">
                        <img
                          className="is-rounded"
                          src={testimonials[testimonial].profilePic}
                          alt="Placeholder "
                        ></img>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-5 k2d">
                        {testimonials[testimonial].title}
                      </p>
                      <p className="subtitle is-6 k2d secondary">
                        <a href={testimonials[testimonial].link} className="subtitle is-6 k2d secondary">
                          {testimonials[testimonial].subtitle}
                        </a>
                      </p>
                    </div>
                  </div>
                  <section className="card-section message is-dark">
                    <p
                      className="content testimonial-desc message-body k2d"
                      style={{ lineHeight: 1.5 }}
                    >
                      {testimonials[testimonial].description}
                    </p>
                  </section>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
