import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          vocab: {
            with: "with",
            thanks: "Thank you !",
            soon: "Coming soon !",
            version: "Version",
          },
          nav: {
            home: "Welcome",
            books: "Books",
            coaching: "Coaching",
            community: "Community",
            privacy: "Privacy"
          },
          cover: {
            tagBold: "Kaio",
            tagline: " - Mind & Body",
            description: "Discover the simplest way to transform your life with Kaio — your personal AI coach! Get motivated daily with over +100 engaging Mind & Body sessions, adapted to you through personalized levels for a healthier, happier life",
            enter_email: "Enter your Email",
            cta: "I want to Test !",
            transform: "Achieve",
            your_life: " Balance",
            yourBody: " your Body",
            yourMind: " your Mind",
          },
          books: {
            finally: "Finally",
            description: "A book about personal growth that is truly personal",
            priceCatch: "Starting at 2€",
            cheaperFree: "(Cheaper is Free)",
            title: "Here are the first 10 pages, we got you 🎁",
            firstPage: "../img/page1-fr.png",
            cta: "Read an extract",
            shopCTA: "🛒 Buy",
          },
          cards: {
            title: "All the cards are in your hand",
            subTitle: "Kaio gives you the tools you need to progress towards your best life",
            card1Emoji: "💪",
            card1Title: "Body",
            card1Content: "Stay active regularly. Move your body every day to feel better and stronger",
            card2Emoji: "🧠",
            card2Title: "Mind",
            card2Content: "Prioritize your inner self. Boost self-love and confidence for a healthier mind",
            card3Emoji: "🤖",
            card3Title: "AI Coach",
            card3Content: "Your AI Coach adapts your journey and keeps you motivated every step of the way",
            card4Emoji: "📈",
            card4Title: "Progress Tracking",
            card4Content: "Get a clear overview of your journey. Reflect on your progress and stay motivated",
            card5Emoji: "🎮",
            card5Title: "Gamification",
            card5Content: "Make wellness fun! Use gamification to optimize success and stick to your routine",
            card6Emoji: "💰",
            card6Title: "Price",
            card6Content: "Transform your life for less than 2€ per month. Affordable wellness for everyone"
          },
          pros: {
            titleMain: "The personal development that will truly",
            titleBold: "change your life",
            first: "+ Inner peace",
            second: "+ Freedom",
            third: "+ Opportunities",
            fourth: "+ Self confidence",
            fifth: "+ Love",
            sixth: "+ Energy",
          },
          cons: {
            first: "- stress",
            second: "- limiting beliefs",
            third: "- comfort zone",
            fourth: "- self-doubt",
            fifth: "- grudges",
            sixth: "- health issues",
          },
          faq: {
            how: "How?",
            cat1Emoji: "📚",
            cat1: "1. Experts",
            cat1Text: "Our approach is grounded in the latest scientific advancements, ensuring every step you take has a significant impact on your life. We collaborate with top specialists in various fields to provide you with reliable and relevant advice. You can be confident that every tip and method is designed to help you achieve your goals effectively and safely.",
            cat2Emoji: "🔄",
            cat2: "2. Habits",
            cat2Text: "We support you in building new, healthy habits that are easy and enjoyable, not a chore. With our continuous support, creating and maintaining positive habits becomes a natural and even enjoyable process. Imagine a future where taking care of yourself becomes second nature, with every small victory bringing you closer to your best self.",
            cat3Emoji: "🎯",
            cat3: "3. Personalization",
            cat3Text: "Create a personalized plan that matches your goals and experiences. Achieve growth that is both within reach and challenging. We consider your preferences, pace, and aspirations to offer you a program that fits you. With clear goals and measurable steps, each progress will be a source of motivation and satisfaction.",
            cat4Emoji: "🫶",
            cat4: "4. Community",
            cat4Text: "Join a community of like-minded individuals aiming to become their best selves and make a positive impact on the world. Together, we form a powerful force of support and encouragement. Share your successes, find inspiration in others' journeys, and feel supported every step of the way. Together, we can achieve great things."
          },
          testimonials: {
            start: "I tried,",
            bold: " and I loved it!",
            marieTitle: "Marie",
            marieSubtitle: "@Marie",
            marieDescription: "Kaio helped me find a balance between mind and body that I didn't know I was missing. With their guidance, I approached my career transition with calm and confidence. The boost in my mindset was even noticed by Snapchat recruiters when I landed the job! Thank you, Kaio! ✨",
            dennisTitle: "Dennis",
            dennisSubtitle: "@Dennis",
            dennisDescription: "Expanding the harmony between my Mind and Body through Kaio has been a game-changer. The content of the sessions has really helped me align my goals with my values and my love of sports. Now, I wake up with a clear sense of purpose, feeling more connected to my 'why' in life, all thanks to the Kaio!",
            barbaraTitle: "Barbara",
            barbaraSubtitle: "@Barbara",
            barbaraDescription: "Kaio brought incredible balance to my life. This growing Mind and Body strength has helped me tackle new projects and live a life full of love. Kaio is now a key part of my daily routine, and it has transformed my life in so many positive ways. 🥰",
            lionelTitle: "Lionel",
            lionelSubtitle: "@Lionel",
            lionelDescription: "Thanks to Kaio, I've found the perfect balance between taking care of myself both mentally and physically. It was very important to me as I navigated different career paths, keeping myself anchored in a powerful routine. This new Mind & Body balance makes me happy every day, and I can't thank Kaio enough for setting me on this fulfilling journey!",
            miraTitle: "Mira",
            miraSubtitle: "@mirasaadallah",
            miraDescription: "Kaio helped me break bad habits and build a healthy morning routine, regular exercise, and self-care. It wasn't easy at first, but the continuous support kept me going. Now, I feel more balanced and energized, ready to take on any day!",
            jeanneTitle: "Jeanne",
            jeanneSubtitle: "@jeanne_lhx",
            jeanneDescription: "Kaio has been a revelation. It helps me find clarity in my professional and personal life by balancing my mind and body. This balance has reduced my frustrations and aligned my actions with my growth and choices. For anyone looking to live more peacefully and purposefully, I highly recommend joining Kaio. 🙌🏻"
          },
          productDetails: {
            title: "LVQM, the adventure towards the life you deserve",
            language: "Language",
            format: "Format",
            ebook: "Ebook",
            paper: "Paperback - Matte finish",
            paperEbook: "Pre-order paperback + Ebook",
            currency: "€",
          },
          coaching: {
            motivationTitle: "You can do it too !",
            motivationText:
              "95% of participants have seen an impact during their first week of coaching and concrete results towards their goals after four weeks",
            lvqm: "LVQM",
            coaching: "COACHING",
            description:
              "LVQM's individual coaching program was created to help you achieve your personal goals, apply the concepts of the book “The adventure towards the life you deserve” and adapt them to your situation",
            tryout: "Activate my test session",
            timeline: {
              title: "Your personalised support",
              firstEmoji: "📖 ",
              firstTitle: "Preparation",
              firstDescription:
                "Dive into the LVQM book, it will be your guide throughout your journey",
              secondEmoji: "👌 ",
              secondTitle: "1st session",
              secondDescription:
                "We discuss your story, your current challenges and your life goals",
              thirdEmoji: "💪 ",
              thirdTitle: "Action plan",
              thirdDescription:
                "Your LVQM coach prepares a personalised program for you so that you can progress in a quick and healthy way",
              fourthEmoji: "🚀 ",
              fourthTitle: "Practice",
              fourthDescription:
                "Integrate the action plan into your daily routine with the help of your coach",
              tryout: "Activate my test session",
              impact1: "1 - Choose your ",
              impact2: "impact areas",
            },
            cta: "I want to Test !",
          },
          coaches: {
            title1: "2 - Choose ",
            title2: "your coach",
          },
          formulas: {
            title1: "3 - Choose ",
            title2: "your plan",
            ebook: "📖 Ebook LVQM",
            group: "🫶 Access to the LVQM private group",
            study: "✍️ 1 personalized assessment",
            plan: "💪 Your custom action plan",
            coaching1: "✨ 1 coaching session (30 minutes)",
            coaching2: "✨ 2 coaching sessions (30 minutes)",
            coaching10: "✨ 10 coaching sessions (30 minutes)",
            goodies: "🎁 1 pack of LVQM goodies",
            starterSubtitle: "Perfect to get started",
            regularSubtitle: "Let's pick things up",
            premiumSubtitle: "Shooting for the stars",
          },
          impacts: {
            impact1: "1 - Choose your ",
            impact2: "impact areas",
            trust: "Gain self confidence",
            forgive: "Forgiveness",
            career: "Change careers",
            passion: "Discover my passion",
            love: "Find love",
            health: "Improve my health",
            goal: "Reach my goals",
            sport: "Exercise more",
            mateoRating: "⭐ 5 (33 ratings)",
            mateoDescription:
              "Thanks to my experiences as an athlete in the French Track & Field team, as a Partnerships Manager at Facebook as well as my personal development journey, I've come to understand how everything is linked to our way of being. I have spent the past few years applying the LVQM concepts to my life and have seen it change for the better, whether it be in my friendships, work, love, family, financial situation or general happiness. Today, my goal is to help you get to the same point and more!",
            mayaRating: "⭐ 5 (26 ratings)",
            mayaDescription:
              "Professional coach for 2 years after 14 years in the corporate world, everything I do is focused on one ultimate goal: to help you break down the barriers that prevent you from fully expressing your potential and embodying your deep identity: unique, authentic and powerful. For this, I intend to pass on to you my contagious energy and all the tools that have allowed me to free myself from my own barriers (impostor syndrome, limiting beliefs... you know what I mean?) and to create a life on my terms. Ready to build the bridge that will lead you to the next stage of life you deserve ?",

            priceTag: "🏷️ Starting at 30 €",
          },
          checkout: {
            order: "💫 Your order is on the way ! 💫",
            info: "Your info",
            payment: "Your payment",
            firstname: "First name",
            lastname: "Last name",
            email: "Email",
            address: "Address",
            zip: "Postal code",
            city: "City",
            country: "Country",
            orderComing: "✉️ Your order is on the way ✨",
            error: "Please fill out all the fields",
            cardInvalid: "Please input valid card info",
          },
          footer: {
            cta: "I want to Test !",
            emoji: "❤️",
            tagline: "Welcome to the KAIO community !",
            description: "Join a community of experts and caring souls. Sometimes a first step into the unknown is the only thing that separates you from a completely new life.",
            insta: "Instagram @kaiocrew",
            instaURL: "https://www.instagram.com/kaiocrew",
            privacy: "Privacy Policy"
          },
          policy: {
            title: "Privacy Policy",
            introduction: {
              title: "Introduction",
              content: "Welcome to Kaio. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy or our practices with regard to your personal information, please contact us at mateo@kaioapp.com."
            },
            dataCollection: {
              title: "Data Collection",
              content: "We collect personal information that you voluntarily provide to us when you register on the app, express an interest in obtaining information about us or our products and services, when you participate in activities on the app (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us. The personal information we collect may include the following: Personal Data: Name, email address, contact information. Progress Data: Data related to your progress and activities within the app. Usage Data: Information about how you use our app, which may include your interactions with the app."
            },
            dataUsage: {
              title: "Data Usage",
              content: "We use personal information collected via our app for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We use the information we collect or receive to: Facilitate account creation and logon process. Send you marketing and promotional communications. Fulfill and manage your orders. Manage user accounts. Post testimonials. Request feedback. To protect our Services. To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract. To respond to legal requests and prevent harm. Deliver targeted advertising to you. Administer prize draws and competitions. To deliver services to the user. To manage user accounts. To perform other business purposes."
            },
            dataProtection: {
              title: "Data Protection",
              content: "We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information."
            },
            cookies: {
              title: "Cookies",
              content: "We may use cookies and similar tracking technologies to access or store information. Cookies are small data files that are placed on your computer or mobile device when you visit a website. We use cookies to help us recognize you as a user, understand your usage patterns, and improve our service. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this app may become inaccessible or not function properly. By using our app, you consent to our use of cookies."
            },
            thirdParties: {
              title: "Third Parties",
              content: "We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service, and marketing efforts. We may allow selected third parties to use tracking technology on the app, which will enable them to collect data on our behalf about how you interact with our app over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity."
            },
            yourRights: {
              title: "Your Rights",
              content: "You have the right to request access to the personal data we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit the settings section of your account or contact us at mateo@kaioapp.com. We will respond to your request within 30 days."
            },
            contact: {
              title: "Contact Us",
              content: "If you have questions or comments about this policy, you may email us at mateo@kaioapp.com "
            }
          }         
        },
      },
      fr: {
        translation: {
          vocab: {
            with: "avec",
            thanks: "Merci !",
            soon: "Bientôt !",
            version: "Version",
          },
          nav: {
            home: "Bienvenue",
            books: "Livres",
            coaching: "Coaching",
            community: "Communauté",
            privacy: "Confidentialité"

          },
          cover: {
            tagBold: "Kaio",
            tagline: " - Corps & Esprit",
            description:
              "Découvre le moyen le plus simple de transformer ta vie avec Kaio — ton coach IA personnel ! Reste motivé(e) chaque jour avec plus de 100 sessions Mind & Body, adaptées à toi grâce à des niveaux personnalisés pour une vie plus saine et plus épanouie !",
            enter_email: "Entre ton Email",
            cta: "Je veux Tester !",
            transform: "Atteins",
            your_life: " l'équilibre",
            yourBody: " ton Corps",
            yourMind: " ton Esprit",
          },
          books: {
            finally: "Enfin",
            description: "Un livre de développement vraiment personnel",
            priceCatch: "Et à partir de 2€",
            cheaperFree: "(Moins cher c'est gratuit)",
            title: "Voici les 10 premières pages, c'est cadeau 🎁",
            cta: "Lire un extrait",
            shopCTA: "🛒 Acheter",
          },
          cards: {
            title: "Tu as toutes les cartes en mains",
            subTitle: "Kaio te donne les outils nécessaires pour progresser vers ta meilleure vie",
            card1Emoji: "💪",
            card1Title: "Corps",
            card1Content: "Reste actif régulièrement. Bouge ton corps chaque jour pour te sentir mieux et plus fort(e)",
            card2Emoji: "🧠",
            card2Title: "Esprit",
            card2Content: "Priorise ton moi intérieur. Renforce l'amour de soi et la confiance pour un esprit plus sain",
            card3Emoji: "🤖",
            card3Title: "Coach IA",
            card3Content: "Ton coach IA adapte ton parcours et te motive à chaque étape",
            card4Emoji: "📈",
            card4Title: "Suivi des progrès",
            card4Content: "Obtiens une vue d'ensemble claire de ton parcours. Réfléchis à tes progrès et reste motivé(e)",
            card5Emoji: "🎮",
            card5Title: "Gamification",
            card5Content: "Rends le bien-être fun ! Utilise les techniques de jeu pour optimiser ton succès et maintenir ta routine",
            card6Emoji: "💰",
            card6Title: "Prix",
            card6Content: "Transforme ta vie pour moins de 2€ par mois. Le bien-être abordable pour tous"
          },
          pros: {
            titleMain: "Le développement personnel qui va vraiment",
            titleBold: "changer ta vie",
            first: "+ de Sérénité",
            second: "+ de Liberté",
            third: "+ d'Opportunités",
            fourth: "+ Confiance en soi",
            fifth: "+ d'Amour",
            sixth: "+ de Forme physique",
          },
          cons: {
            first: "- de stress",
            second: "- de croyances limitantes",
            third: "- de zone de comfort",
            fourth: "- de remise en question",
            fifth: "- de ressentiment",
            sixth: "- de problèmes de santé",
          },
          faq: {
            how: "Comment ?",
            cat1Emoji: "📚",
            cat1: "1. Experts",
            cat1Text: "Notre approche est fondée sur les dernières avancées scientifiques, garantissant que chaque étape que tu franchis a un impact significatif sur ta vie. Nous collaborons avec les meilleurs spécialistes dans divers domaines pour t'offrir des conseils fiables et pertinents. Tu peux être certain que chaque conseil et chaque méthode sont conçus pour t'aider à atteindre tes objectifs de manière efficace et sécurisée.",
            cat2Emoji: "🔄",
            cat2: "2. Habitudes",
            cat2Text: "Nous t'aidons à construire de nouvelles habitudes saines qui sont faciles et agréables, sans être une corvée. Avec notre soutien continu, créer et maintenir des habitudes positives devient un processus naturel et même agréable. Imagine un avenir où prendre soin de toi devient une seconde nature, chaque petite victoire te rapprochant de ta meilleure version.",
            cat3Emoji: "🎯",
            cat3: "3. Personnalisation",
            cat3Text: "Crée un plan personnalisé qui correspond à tes objectifs et expériences. Atteins une croissance à la fois accessible et stimulante. Nous prenons en compte tes préférences, ton rythme et tes aspirations pour te proposer un programme qui te ressemble. Avec des objectifs clairs et des étapes mesurables, chaque progrès sera une source de motivation et de satisfaction.",
            cat4Emoji: "🫶",
            cat4: "4. Communauté",
            cat4Text: "Rejoins une communauté de personnes partageant les mêmes idées, visant à devenir la meilleure version d'elles-mêmes et à avoir un impact positif sur le monde. Ensemble, nous formons une force puissante de soutien et d'encouragement. Partage tes réussites, trouve de l'inspiration dans les parcours des autres et sens-toi soutenu à chaque étape de ton cheminement. Ensemble, nous pouvons réaliser de grandes choses."
          },
          testimonials: {
            start: "J'ai essayé,",
            bold: " j'ai adoré!",
            marieTitle: "Marie",
            marieSubtitle: "@Marie",
            marieDescription: "Kaio m'a aidée à trouver un équilibre entre mon esprit et mon corps que je ne savais même pas que je cherchais. Grâce à leurs conseils, j'ai abordé ma transition professionnelle avec calme et confiance. Mon nouvel état d'esprit a même été remarqué par les recruteurs de Snapchat quand j'ai obtenu le poste ! Merci, Kaio ! ✨",
            dennisTitle: "Dennis",
            dennisSubtitle: "@Dennis",
            dennisDescription: "Renforcer l'harmonie entre mon esprit et mon corps grâce à Kaio a été une véritable révélation. Le contenu des sessions m'a vraiment aidé à aligner mes objectifs avec mes valeurs et mon amour du sport. Maintenant, je me réveille avec un sens clair du but, me sentant plus connecté à mon 'pourquoi' dans la vie !",
            barbaraTitle: "Barbara",
            barbaraSubtitle: "@Barbara",
            barbaraDescription: "Kaio a apporté un équilibre incroyable dans ma vie. Cette force croissante de l'esprit et du corps m'a aidée à entreprendre de nouveaux projets et à vivre une vie pleine d'amour. Kaio fait désormais partie intégrante de ma routine quotidienne et a transformé ma vie de tant de façons positives. 🥰",
            lionelTitle: "Lionel",
            lionelSubtitle: "@Lionel",
            lionelDescription: "Grâce à Kaio, j'ai trouvé le parfait équilibre entre prendre soin de moi mentalement et physiquement. C'était très important pour moi alors que je naviguais entre différentes carrières, me maintenant ancré dans une routine puissante. Ce nouvel équilibre esprit et corps me rend heureux chaque jour, et je remercie Kaio de m'avoir mis sur cette voie épanouissante !",
            miraTitle: "Mira",
            miraSubtitle: "@mirasaadallah",
            miraDescription: "Kaio m'a aidée à abandonner de mauvaises habitudes et à instaurer une routine matinale saine, un exercice régulier et des soins personnels. Ce n'était pas facile au début, mais le soutien continu m'a permis de tenir bon. Maintenant, je me sens plus équilibrée et énergisée, prête à affronter chaque jour !",
            jeanneTitle: "Jeanne",
            jeanneSubtitle: "@jeanne_lhx",
            jeanneDescription: "Kaio a été une révélation. Me lancer dans cette aventure m'a aidé à trouver de la clarté dans ma vie professionnelle et personnelle en équilibrant mon esprit et mon corps. Cet équilibre a réduit mes frustrations et aligné mes actions avec ma croissance et mes choix. Pour ceux qui cherchent à vivre plus sereinement et avec sens, je recommande vivement de rejoindre Kaio. 🙌🏻"
          },
          productDetails: {
            title: "LVQM, l'aventure vers la vie que tu mérites",
            language: "Langue",
            format: "Format",
            ebook: "Ebook",
            paper: "Livre papier - Finition matte",
            paperEbook: "Pré-commande livre papier + Ebook",
            currency: "€",
          },
          coaching: {
            motivationTitle: "Tu peux y arriver aussi !",
            motivationText:
              "95% des participants ont constaté un impact dès leur première semaine de coaching et des résultats concrets dans l’accomplissement de leurs objectifs après quatres semaines d’accompagnement",
            lvqm: "LVQM",
            coaching: "COACHING",
            description:
              "Le programme de coaching individuel d’LVQM a été créé pour t’aider à atteindre tes objectifs personnels, à appliquer les concepts du livre “L’aventure vers la vie que tu mérites” et à les adapter à ta situation",
            tryout: "Activer ma séance d'essai",
            timeline: {
              title: "Ton accompagnement personnalisé",
              firstEmoji: "📖 ",
              firstTitle: "Préparation",
              firstDescription:
                "Plonge-toi dans le livre LVQM, ce sera ton guide durant ton accompagnement",
              secondEmoji: "👌 ",
              secondTitle: "1ère séance",
              secondDescription:
                "On discute ensemble de ton parcours, de tes challenges actuels et de tes objectifs de vie",
              thirdEmoji: "💪 ",
              thirdTitle: "Plan d'action",
              thirdDescription:
                "Ton coach LVQM te prépare un programme sur mesure afin de progresser sainement et rapidement",
              fourthEmoji: "🚀 ",
              fourthTitle: "Pratique",
              fourthDescription:
                "Incorpore les exercices et mises en situation de ton plan d’action dans ton quotidien avec l’aide de ton coach",
            },
            cta: "Je veux Tester' !",
          },
          coaches: {
            title1: "2 - Choisis ",
            title2: "ton coach",
          },
          formulas: {
            title1: "3 - Choisis ",
            title2: "ta formule",
            ebook: "📖 Ebook LVQM",
            group: "🫶 Accès au groupe privé LVQM",
            study: "✍️ 1 étude personnalisée du coach",
            plan: "💪 Ton plan d'action sur mesure",
            coaching1: "✨ 1 séance de coaching de 30 minutes",
            coaching2: "✨ 2 séances de coaching de 30 minutes",
            coaching10: "✨ 10 séances de coaching de 30 minutes",
            goodies: "🎁 1 pack de goodies LVQM",
            starterSubtitle: "Parfait pour commencer",
            regularSubtitle: "On accélère le rythme",
            premiumSubtitle: "Visons les étoiles",
          },
          impacts: {
            impact1: "1 - Choisis tes ",
            impact2: "zones de travail",
            trust: "Avoir confiance en moi",
            forgive: "Réussir à pardonner",
            career: "Changer de carrière",
            passion: "Découvrir ma passion",
            love: "Trouver l'amour",
            health: "Améliorer ma santé",
            goal: "Atteindre mes objectifs",
            sport: "Faire plus de sport",
            mateoRating: "⭐ 5 (33 avis)",
            mateoDescription:
              "Au fil de mes expériences en équipe de France d’athlétisme, en tant que manager des partenariats chez Facebook ainsi que mon parcours de développement personnel, j’ai compris à quel point tout était lié à notre manière d’être.  J’ai passé les dernières années à appliquer les concepts d'LVQM à ma vie et l’ai vue changer pour le meilleur que ce soit concernant mes relations amicales, professionnelles, amoureuses, familiales, ma situation financière ou mon bonheur en général.  Aujourd’hui, mon but est de t’aider à arriver au même point et plus encore !",
            mayaRating: "⭐ 5 (26 avis)",
            mayaDescription:
              "Coach professionnelle depuis 2 ans après 14 ans de carrière en entreprise, tout ce que je fais est tourné vers un but ultime : t’aider à briser les barrières qui t’empêchent d’exprimer pleinement ton potentiel et d’incarner ton identité profonde : unique, authentique et puissante. Pour cela, je compte bien te transmettre mon énergie contagieuse et tous les outils éprouvés et approuvés qui m’ont permis de me libérer de mes propres barrières (syndrome de l’imposteur, croyances limitantes… tu vois le truc?) et de créer une vie selon mes termes. Prêt(e) à construire la passerelle qui te mènera vers la prochaine étape de la vie que tu mérites ?",
            priceTag: "🏷️ À partir de 30 €",
          },
          checkout: {
            order: "Ta commande",
            info: "Tes informations",
            payment: "Ton paiement",
            firstname: "Prénom",
            lastname: "Nom",
            email: "Email",
            address: "Adresse",
            zip: "Code postal",
            city: "Ville",
            country: "Pays",
            orderComing: "✉️ Ta commande est partie ✨",
            error: "Merci de remplir tous les champs",
            cardInvalid: "Merci d'entrer des informations de cartes valides",
          },
          footer: {
            cta: "Je veux Tester !",
            emoji: "❤️",
            tagline: "Bienvenue dans la communauté KAIO !",
            description: "Rejoins une communauté d'experts et d'âmes bienveillantes. Parfois, un premier pas dans l'inconnu est la seule chose qui vous sépare d'une vie complètement nouvelle.",
            insta: "Instagram @kaiocrew",
            instaURL: "https://www.instagram.com/kaiocrew",
            privacy: "Politique de confidentialité"
          },
          policy: {
            title: "Politique de confidentialité",
            introduction: {
              title: "Introduction",
              content: "Bienvenue chez Kaio. Nous nous engageons à protéger vos informations personnelles et votre droit à la vie privée. Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité ou nos pratiques en matière de protection des renseignements personnels, veuillez nous contacter à l'adresse mateo@kaioapp.com."
            },
            dataCollection: {
              title: "Collecte de données",
              content: "Nous collectons des informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez sur l'application, manifestez un intérêt pour obtenir des informations sur nous ou nos produits et services, participez à des activités sur l'application (comme poster des messages dans nos forums en ligne ou participer à des concours, des concours ou des cadeaux) ou autrement lorsque vous nous contactez. Les informations personnelles que nous collectons peuvent inclure les éléments suivants : Données personnelles : nom, adresse électronique, coordonnées. Données de progression : données relatives à vos progrès et activités au sein de l'application. Données d'utilisation : informations sur la façon dont vous utilisez notre application, qui peuvent inclure vos interactions avec l'application."
            },
            dataUsage: {
              title: "Utilisation des données",
              content: "Nous utilisons les informations personnelles collectées via notre application à diverses fins commerciales décrites ci-dessous. Nous traitons vos informations personnelles à ces fins en nous appuyant sur nos intérêts commerciaux légitimes, afin de conclure ou d'exécuter un contrat avec vous, avec votre consentement et/ou pour respecter nos obligations légales. Nous utilisons les informations que nous collectons ou recevons pour : Faciliter la création et le processus de connexion de compte. Vous envoyer des communications marketing et promotionnelles. Exécuter et gérer vos commandes. Gérer les comptes d'utilisateurs. Publier des témoignages. Demander des commentaires. Protéger nos services. Appliquer nos conditions générales, conditions et politiques à des fins commerciales, pour respecter les exigences légales et réglementaires ou dans le cadre de notre contrat. Répondre aux demandes légales et prévenir les dommages. Diffuser des publicités ciblées. Administrer des tirages au sort et des concours. Fournir des services à l'utilisateur. Gérer les comptes d'utilisateurs. Exécuter d'autres fins commerciales."
            },
            dataProtection: {
              title: "Protection des données",
              content: "Nous avons mis en place des mesures de sécurité techniques et organisationnelles appropriées pour protéger la sécurité de toute information personnelle que nous traitons. Cependant, malgré nos garanties et nos efforts pour sécuriser vos informations, aucune transmission électronique sur Internet ou technologie de stockage de l'information ne peut être garantie à 100% sécurisée, donc nous ne pouvons pas promettre ou garantir que les hackers, cybercriminels ou autres tiers non autorisés ne seront pas capables de contourner notre sécurité et de collecter, accéder, voler ou modifier vos informations de manière incorrecte."
            },
            cookies: {
              title: "Cookies",
              content: "Nous pouvons utiliser des cookies et des technologies de suivi similaires pour accéder ou stocker des informations. Les cookies sont de petits fichiers de données qui sont placés sur votre ordinateur ou appareil mobile lorsque vous visitez un site web. Nous utilisons des cookies pour nous aider à vous reconnaître en tant qu'utilisateur, comprendre vos habitudes d'utilisation et améliorer notre service. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou certains cookies, ou pour vous avertir lorsque des sites web configurent ou accèdent à des cookies. Si vous désactivez ou refusez les cookies, veuillez noter que certaines parties de cette application peuvent devenir inaccessibles ou ne pas fonctionner correctement. En utilisant notre application, vous consentez à notre utilisation des cookies."
            },
            thirdParties: {
              title: "Tiers",
              content: "Nous pouvons partager vos données avec des fournisseurs tiers, des prestataires de services, des contractants ou des agents qui effectuent des services pour nous ou en notre nom et nécessitent un accès à ces informations pour effectuer ce travail. Des exemples incluent : le traitement des paiements, l'analyse des données, la livraison par courrier électronique, les services d'hébergement, le service client et les efforts de marketing. Nous pouvons permettre à des tiers sélectionnés d'utiliser une technologie de suivi sur l'application, ce qui leur permettra de collecter des données en notre nom sur la manière dont vous interagissez avec notre application au fil du temps. Ces informations peuvent être utilisées, entre autres, pour analyser et suivre les données, déterminer la popularité de certains contenus, pages ou fonctionnalités, et mieux comprendre l'activité en ligne."
            },
            yourRights: {
              title: "Vos droits",
              content: "Vous avez le droit de demander l'accès aux données personnelles que nous collectons à votre sujet, de modifier ces informations ou de les supprimer dans certaines circonstances. Pour demander à consulter, mettre à jour ou supprimer vos informations personnelles, veuillez visiter la section des paramètres de votre compte ou nous contacter à l'adresse mateo@kaioapp.com. Nous répondrons à votre demande dans les 30 jours."
            },
            contact: {
              title: "Nous contacter",
              content: "Si vous avez des questions ou des commentaires concernant cette politique, vous pouvez nous envoyer un courriel à l'adresse mateo@kaioapp.com"
            }
          }
        },
      },
    },
  });

export default i18n;
